import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PetPatient } from '@app/shared/models/pet-patient';
import { Race } from '@app/shared/models/races';
import { Species } from '@app/shared/models/species';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export const PET_PATIENT_URL = 'pets';

export type FilterSearch = {
  name?: string;
  responsibleCpf?: string;
  responsibleName?: string;
};

export type FilterPaginate = {
  page: number;
  limit: number;
  orderBy?: string;
  sortOrder?: string;
};

@Injectable({
  providedIn: 'root'
})
export class PetPatientService {
  constructor(private http: HttpClient) {}

  getPetsByResponsibleCpf(cpf: string): Observable<PetPatient[]> {
    return this.http.get<PetPatient[]>(`${environment.apiRoot}${PET_PATIENT_URL}`, {
      params: { cpf }
    });
  }

  getSpecies(): Observable<Species[]> {
    return this.http.get<Species[]>(`${environment.apiRoot}${PET_PATIENT_URL}/species`);
  }

  getRaces(speciesId: string): Observable<Race[]> {
    return this.http.get<Race[]>(`${environment.apiRoot}${PET_PATIENT_URL}/${speciesId}/races`);
  }

  save(patient: PetPatient): Observable<PetPatient> {
    return this.http.post<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}`, patient);
  }

  getPetById(id: string): Observable<PetPatient> {
    return this.http.get<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}/${id}`);
  }

  update(id: string, data: any, healthProfessionalId?: string): Observable<PetPatient> {
    let params = new HttpParams();
    if (healthProfessionalId) {
      params = params.set('healthProfessional', healthProfessionalId);
    }
    return this.http.put<PetPatient>(`${environment.apiRoot}${PET_PATIENT_URL}/${id}`, data, {
      params
    });
  }

  searchPaginate(params: FilterSearch) {
    let httpParams = new HttpParams();
    if (params) {
      Object.keys(params)
        .filter(key => params[key])
        .forEach(k => {
          httpParams = httpParams.set(k, params[k]);
        });
    }

    return this.http.get<{ list: PetPatient[]; totalRecord: number }>(
      `${environment.apiRoot}${PET_PATIENT_URL}/search-paginate`,
      { params: httpParams }
    );
  }

  switchReponsible(id: string, cpfNewReponsible: string) {
    return this.http.put(
      `${environment.apiRoot}${PET_PATIENT_URL}/${id}/responsible/${cpfNewReponsible}`,
      {}
    );
  }
}
